import { autoinject, bindable } from "aurelia-framework";
import { ValidationController, ValidationRules } from "aurelia-validation";
import { AddressModel, OptionItemDto } from '../../services/generated-services';
import { UniqueIdentifierGenerator } from '../../services/unique-identifier-generator';
import { DeepObserver } from "utils/deep-observer";
import { Disposable } from "aurelia-binding";

@autoinject()
export class AddressControl {

    @bindable value: AddressModel;
    @bindable states: OptionItemDto[];
    @bindable required: boolean = false;
    elementId: string;
    subscriptions: Disposable[] = [];

    constructor(uuidGenerator: UniqueIdentifierGenerator,
        private readonly deepObserver: DeepObserver,
        private readonly validationController: ValidationController) {
        this.elementId = uuidGenerator.uuidv4();
    }

    attached() {
        const rules = ValidationRules
            .ensure((address: AddressModel) => address.addressLine1).required().when((model) => this.required || this.oneFieldProvided(model))
            .maxLength(100)
            .ensure((address: AddressModel) => address.suburb).required().when((model) => this.required || this.oneFieldProvided(model))
            .maxLength(100)
            .ensure((address: AddressModel) => address.state).required().when((model) => this.required || this.oneFieldProvided(model))
            .ensure((address: AddressModel) => address.postCode).required().when((model) => this.required || this.oneFieldProvided(model))
            .maxLength(20).matches(/[0-9]{4}/).withMessage("Invalid postcode")
            .rules;

        this.validationController.addObject(this.value, rules);
        this.subscriptions.push(
            this.deepObserver.observe(this, "value", () => {
                if (!this.oneFieldProvided(this.value) && !this.required) {
                    //revalidate and clear the errors if no field is provided and the address is not required
                    this.validationController.validate({ object: this.value });
                }
            })
        );
    }

    oneFieldProvided(address: AddressModel): boolean {
        return !!address.state || !!address.addressLine1 || !!address.postCode || !!address.suburb;
    }

    detached() {
        this.validationController.removeObject(this.value);
        while (this.subscriptions.length) {
            this.subscriptions.pop().dispose();
        }
    }
}