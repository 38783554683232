import { autoinject } from 'aurelia-framework';

@autoinject()
export class Memoize<T> {
    private memoized: Promise<T>;

    constructor(getter: () => Promise<T>) {
        this.memoized = new Promise((resolve, reject) => {
            getter()
                .then(t => resolve(t));
        });
    }

    async get(): Promise<T> {
        return await this.memoized;
    }
}


