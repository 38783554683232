import { RenderInstruction, ValidateResult } from 'aurelia-validation';

export class ValidationRenderer {

    render(instruction: RenderInstruction) {
        for (let { result, elements } of instruction.unrender) {
            for (let element of elements) {
                this.remove(element, result);
            }
        }

        for (let { result, elements } of instruction.render) {
            // TODO: Test for result.valid required due to what seems to be a bug introduced in aurelia-validation v1.0.0-beta.
            if (!result.valid) {
                for (let element of elements) {
                    this.add(element, result);
                }
            }
        }
    }

    private add(element: Element, result: ValidateResult) {
        switch (element.nodeName) {
            case "TEXT-INPUT":
            case "DATE-PICKER":
                element.querySelector('input').classList.add('is-invalid');
                break;
            case "TEXTAREA-INPUT":
                element.querySelector('textarea').classList.add('is-invalid');
                break;
            case "SELECT-INPUT":
                element.querySelector('.ts-control').classList.add('is-invalid');
                break;
            case "YES-NO":
            case "YES-NO-CUSTOM":
            case "CHECKBOX-LIST":
            case "RADIO-BUTTON-LIST":
                element.querySelectorAll('.custom-control-input').forEach(node => node.classList.add('is-invalid'));
                break;
        }

        element.parentElement.classList.add('is-invalid');
        const message: any = document.createElement('div');
        message.className = 'invalid-feedback';
        message.textContent = result.message;
        message.id = `validation-message-${result.id}`;

        if (element.parentElement.classList.contains('input-group')) {

            element.parentElement.parentElement.appendChild(message);
            element.parentElement.parentElement.classList.add('is-invalid');

        } else if (element.parentElement.nodeName.toLowerCase() === 'label') {

            const div = document.createElement('div');
            div.id = message.id;
            message.id = '';
            div.appendChild(message);
            element.parentElement.parentElement.appendChild(div);

        } else {
            //add the message as a sibling
            element.parentElement.appendChild(message);
        }
    }

    private remove(element: Element, result: ValidateResult) {
        switch (element.nodeName) {
            case "TEXT-INPUT":
            case "DATE-PICKER":
                let dpTarget = element.querySelector('input');
                if (dpTarget) { dpTarget.classList.remove('is-invalid'); }
                break;
            case "TEXTAREA-INPUT":
                let taTarget = element.querySelector('textarea');
                if (taTarget) { taTarget.classList.remove('is-invalid'); }
                break;
            case "SELECT-INPUT":
                let selTarget = element.querySelector('.ts-control');
                if (selTarget) { selTarget.classList.remove('is-invalid'); }
                break;
            case "YES-NO":
            case "YES-NO-CUSTOM":
            case "CHECKBOX-LIST":
            case "RADIO-BUTTON-LIST":
                element.querySelectorAll('.custom-control-input').forEach(node => node.classList.remove('is-invalid'));
                break;
        }
        element.parentElement.classList.remove('is-invalid');
        if (!element.parentElement) { return; } // IE, sometimes, for some unknown reason.
        if (element.parentElement.classList.contains('input-group')) {
            const message: any = element.parentElement.parentElement.querySelector(`#validation-message-${result.id}`);
            if (message) {
                element.parentElement.parentElement.removeChild(message);
            }
            element.parentElement.parentElement.classList.remove('is-invalid');
        } else if (element.parentElement.nodeName.toLowerCase() === 'label') {
            const message: any = element.parentElement.parentElement.querySelector(`#validation-message-${result.id}`);
            if (message) {
                element.parentElement.parentElement.removeChild(message);
            }
        } else {
            const message: any = element.parentElement.querySelector(`#validation-message-${result.id}`);
            if (message) {
                element.parentElement.removeChild(message);
            }
        }
    }
}
