import { autoinject } from 'aurelia-framework';
import { ConfigurationSettingsDto, SystemService } from './generated-services';
import { Memoize } from './memoize';

@autoinject()
export class ConfigService {

    private configuration: Memoize<ConfigurationSettingsDto>;

    constructor(systemController: SystemService) {
        this.configuration = new Memoize(async () => {
            return systemController.getConfiguration();
        });
    }

    async getConfig(): Promise<ConfigurationSettingsDto> {
        return await this.configuration.get();
    }
}