import { autoinject } from 'aurelia-framework';
import { AppointmentService, QuestionnaireResponseDto, ResourceAvailabilityResponse } from './generated-services';
import { GlobalStateService } from './global-state-service';
import { Memoize } from './memoize';

@autoinject()
export class ResourceService {
    private Resources: Memoize<ResourceAvailabilityResponse> = null;

    constructor(private readonly appointmentController: AppointmentService,
        private readonly globalStateService: GlobalStateService) {
    }

    async filterResources(questionnaire: QuestionnaireResponseDto): Promise<ResourceAvailabilityResponse> {
        for (let response of questionnaire.responses) {
            let strippedQuestionText = this.stripHtml(response.questionText);
            response.questionText = strippedQuestionText;

            for (let availableResponse of response.availableResponses) {
                let strippedAvailableResponseResponseText = this.stripHtml(availableResponse.responseText);
                let strippedAvailableResponseDisplayText = this.stripHtml(availableResponse.displayText);
                availableResponse.responseText = strippedAvailableResponseResponseText;
                availableResponse.displayText = strippedAvailableResponseDisplayText;
            }
        }
        this.Resources = new Memoize(async () => {
            return this.appointmentController.getResourcesByQuestionnaire(
                this.globalStateService.getService().urlSegment, questionnaire);
        });
        return await this.getResources();
    }

    async getResources(): Promise<ResourceAvailabilityResponse> {
        if (!this.Resources) {
            console.error("Attempting to access Resource data without filtering by questionnaire responses");
        }

        return await this.Resources.get();
    }

    stripHtml(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }
}