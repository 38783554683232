import { autoinject, bindable, computedFrom } from 'aurelia-framework';
import { WizardSteps } from 'components/appointment-steps/appointment-steps';
import { ScheduleType } from 'services/generated-services';
import { GlobalStateService } from 'services/global-state-service';

export interface IProgressStep {
    stepKey: WizardSteps;
    description: string;
    hide?: boolean;
}

@autoinject()
export class ProgressBar {

    @bindable currentStep: WizardSteps;
    wizardStepOptions: IProgressStep[]
    skipStepOne: boolean = false;

    constructor(private readonly globalStateService: GlobalStateService) {
        const service = this.globalStateService.getService();
        const isFixedSchedule = service.scheduleType == ScheduleType.Fixed;
        this.skipStepOne = !service.hasQuestionnaire;
        this.wizardStepOptions = [
            {
                stepKey: WizardSteps.appointmentQuestions,
                description: isFixedSchedule ? 'Appointment type' : 'Booking type',
                hide: this.skipStepOne
            },
            {
                stepKey: WizardSteps.appointmentSelect,
                description: `Select ${isFixedSchedule ? 'an appointment' : `${service.indefiniteArticleResourceFriendlyName} ${service.resourceFriendlyName}`}`,
            },
            {
                stepKey: WizardSteps.appointmentDetails,
                description: 'Your details'
            },
            {
                stepKey: WizardSteps.appointmentReselect,
                description: `Reselect ${isFixedSchedule ? 'an appointment' : `${service.indefiniteArticleResourceFriendlyName} ${service.resourceFriendlyName}`}`,
            },
            {
                stepKey: WizardSteps.appointmentConfirmed,
                description: `Confirmation`
            },
        ];
    }
    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getStatusClass(step: WizardSteps) {
        const progressClass = 'active';
        const completeClass = 'complete';
        const stepIsComplete = this.stepIsComplete(this.currentStep, step);

        if (this.currentStep == step && stepIsComplete) {
            return progressClass;
        }

        if (stepIsComplete) {
            return completeClass;
        }

        if (this.currentStep == step) {
            return progressClass;
        }
        return '';
    }

    @computedFrom("currentStep")
    get currentStepNumber() {
        return this.visibleSteps.findIndex(x => x.stepKey == this.currentStep) + 1;
    }

    @computedFrom("currentStep")
    get currentStepDescription() {
        const currentStep = this.wizardStepOptions.find(x => x.stepKey == this.currentStep);
        return currentStep.description;
    }

    private stepIsComplete(currentStep: WizardSteps, step: WizardSteps): boolean {
        if (currentStep == WizardSteps.appointmentConfirmed) {
            return true;
        }

        const currentStepIndex = this.wizardStepOptions.findIndex(i => i.stepKey == currentStep);
        const stepIndex = this.wizardStepOptions.findIndex(i => i.stepKey == step);
        if (currentStepIndex == -1 || stepIndex == -1) {
            return;
        }
        return currentStepIndex > stepIndex;
    }

    @computedFrom("currentStep", "wizardStepOptions")
    get visibleSteps(): IProgressStep[] {
        if (this.currentStep == 'appointmentReselect') {
            return this.wizardStepOptions.filter(x => !x.hide);
        }
        return this.wizardStepOptions.filter(x => !x.hide && x.stepKey !== 'appointmentReselect')
    }
}