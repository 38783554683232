
import { autoinject, computedFrom } from 'aurelia-framework';
import { GlobalStateService } from 'services/global-state-service';
import { AppointmentService, PublicAppointmentDto } from '../../services/generated-services';

@autoinject()
export class CancelAppointment {

    token: string;
    isCancelling: boolean = false;
    validated: boolean | null = null;
    cancelled: boolean | null = null;
    appointment: PublicAppointmentDto;

    @computedFrom('validated')
    get showTokenIsInvalid(): boolean {
        return this.validated !== null && !this.validated &&
            this.appointment === null;
    }

    @computedFrom('validated')
    get showCancellationIsInvalid(): boolean {
        return this.validated !== null && !this.validated &&
            this.appointment !== null;
    }

    @computedFrom('validated')
    get showConfirmCancellation(): boolean {
        return this.validated !== null && this.validated && !this.cancelled;
    }

    @computedFrom('cancelled')
    get showCancellationSuccessful(): boolean {
        return this.cancelled !== null && this.cancelled;
    }

    @computedFrom('cancelled')
    get showCancellationFailed(): boolean {
        return this.cancelled !== null && !this.cancelled;
    }

    constructor(
        private readonly appointmentController: AppointmentService,
        private readonly globalStateService:GlobalStateService,
        ) { }


    get slotDescription():string{
        var service = this.globalStateService.getService();
        return `${service.directObjectNoun.toLowerCase()}`;
    }


    async activate(params: { token: string }) {
        this.token = params.token;
        try {
            const validate = await this.appointmentController.validateCancelAppointment(this.globalStateService.getService().urlSegment,this.token);
            this.validated = validate.success;
            this.appointment = validate.result;
        } catch (err) {
            this.validated = false;
        }
    }

    async cancelAppointment() {
        try {
            this.isCancelling = true;
            const cancel = await this.appointmentController.cancelAppointment(this.globalStateService.getService().urlSegment,this.token);
            this.isCancelling = false;
            this.cancelled = cancel.success;
        } catch (err) {
            this.isCancelling = false;
            this.cancelled = false;
        }
    }
}