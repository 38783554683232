import { computedFrom, observable } from 'aurelia-binding';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Disposable, autoinject, bindable } from 'aurelia-framework';
import { BindingSignaler } from 'aurelia-templating-resources';
import dayjs from 'dayjs';
import { GlobalStateService } from 'services/global-state-service';
import { darwinToLocalStartOfDay, localToDarwinStartOfDay } from 'utils/date-utils';
import { AppointmentService, ResourceAvailability, ResourceScheduleFilterDto, TimeSlotDto } from '../../services/generated-services';

export interface ITimeSlot {
    startDateTime: string;
    endDateTime: string;
}

@autoinject()
export class ResourceDateAndTimePicker {

    @bindable() selectedTimeSlot: TimeSlotDto;
    @bindable() resource: ResourceAvailability;
    @observable() localDate: dayjs.Dayjs;

    loadingPrevious: boolean = false;
    loadingNext: boolean = false;
    loadingTimeSlots: boolean = false;
    subscriptions: Disposable[] = [];

    constructor(private ea: EventAggregator,
        private appointmentController: AppointmentService,
        private bindingSignaler: BindingSignaler,
        private readonly globalStateService: GlobalStateService) { }

    availableTimeSlots: TimeSlotDto[] = [];

    resourceChanged() {
        this.localDate = darwinToLocalStartOfDay(this.resource.firstFutureAppointmentDate);
    }

    localDateChanged() {
        this.loadSchedules();
    }

    @computedFrom("resource")
    get enabledDates(): dayjs.Dayjs[] {
        return this.resource.datesWithAppointments.map(x => darwinToLocalStartOfDay(x));
    }

    async loadSchedules() {
        this.loadingTimeSlots = true;

        const serviceUrl = this.globalStateService.getService().urlSegment
        let filter = new ResourceScheduleFilterDto();
        filter.resourceId = this.resource.resourceDetail.resourceId;
        //todo: convert to darwin start of day
        filter.date = localToDarwinStartOfDay(this.localDate);
        this.availableTimeSlots = await this.appointmentController.getAvailableTimeSlots(serviceUrl, filter);

        this.loadingNext = false;
        this.loadingPrevious = false;
        this.loadingTimeSlots = false;

        //required for active button styling
        this.bindingSignaler.signal('updateTimeSlotActive');
    }

    setAppointmentTime(timeSlot: TimeSlotDto) {
        this.selectedTimeSlot = timeSlot;
        this.bindingSignaler.signal('updateTimeSlotActive');
        this.ea.publish('scrollReserveTimeSlotToView');
    }

    @computedFrom('localDate', 'resource')
    get disablePreviousButton(): boolean {
        return !this.previousDay;
    }

    @computedFrom('localDate', 'resource')
    get disableNextButton(): boolean {
        return !this.nextDay;
    }

    @computedFrom('localDate', 'resource')
    get previousDay(): dayjs.Dayjs {
        let darwinStartOfDay = localToDarwinStartOfDay(this.localDate)
        let previousDays = this.resource.datesWithAppointments.filter(x => x.isBefore(darwinStartOfDay));
        if (previousDays.length > 0) {
            return darwinToLocalStartOfDay(previousDays[previousDays.length - 1]);
        }
        return null;
    }

    @computedFrom('localDate', 'resource')
    get nextDay(): dayjs.Dayjs {
        const darwinStartOfDay = localToDarwinStartOfDay(this.localDate);
        const nextDays = this.resource.datesWithAppointments.filter(x => x.isAfter(darwinStartOfDay));
        if (nextDays.length > 0) {
            const nextDay = darwinToLocalStartOfDay(nextDays[0]);
            return nextDay;
        }
        return null;
    }

    goToPreviousDay() {
        this.loadingPrevious = true;
        this.localDate = this.previousDay;
    }

    goToNextDay() {
        this.loadingNext = true;
        this.localDate = this.nextDay;
    }

    @computedFrom('localDate')
    get showAppointmentTimes() {
        return this.localDate && this.localDate.isValid();
    }

    isTimeSlotActive(timeSlot: TimeSlotDto): boolean {
        if (this.selectedTimeSlot) {
            return timeSlot.start.isSame(this.selectedTimeSlot.start, 'minute');
        }
        return false;
    }

    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    get inDifferentTimezone(): boolean {
        let tz = dayjs.tz.guess();
        return tz != 'Australia/Darwin';
    }
}