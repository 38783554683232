import { computedFrom } from 'aurelia-binding';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { WizardSteps } from 'components/appointment-steps/appointment-steps';
import * as _ from 'lodash';
import { ActionType, AvailableResponseDto, ConfirmAppointmentDto, QuestionResponseDto } from '../../../../services/generated-services';

@autoinject()
export class Question {

    @bindable() booking: ConfirmAppointmentDto;
    @bindable() question: QuestionResponseDto;
    @bindable() currentStep: WizardSteps;

    constructor(private router: Router) {
    }

    private get currentResponse(): AvailableResponseDto | null {
        return this.question.responseOrder ?
            _.find(this.question.availableResponses, r => r.responseOrder === this.question.responseOrder) :
            null;
    }

    @computedFrom('question.responseOrder')
    get shouldDisplayResponseText(): boolean {
        const response = this.currentResponse;
        return response && response.actionType === ActionType.Display;
    }

    @computedFrom('question.responseOrder')
    get responseText(): string {
        const response = this.currentResponse;
        return response ? response.displayText : '';
    }

    @computedFrom('question')
    get isQuestion(): boolean {
        return this.question.availableResponses.length > 1;
    }

    @computedFrom('question')
    get isStatement(): boolean {
        return this.question.availableResponses.length === 1;
    }

    @computedFrom('question')
    get statementButtonText(): string {
        return this.isStatement &&
            this.question.availableResponses[0].responseText || 'Continue';
    }

    @computedFrom('question.responseOrder')
    get isDismissed(): boolean {
        return this.isStatement &&
            this.question.responseOrder === this.question.availableResponses[0].responseOrder;
    }

    dismissStatement() {
        this.question.responseOrder = this.question.availableResponses[0].responseOrder;
    }
}