import dayjs from "dayjs";

export class DateTimeFormatValueConverter {
  toView(value: dayjs.Dayjs, format?: string, noValueString: string = "No date specified"): string {

    if (value == null) {
      return noValueString;
    }

    if (!dayjs.isDayjs(value)) {
      value = dayjs(value);
    }

    if (!value.isValid()) {
      console.error("Invalid date provided to date format value converter: " + value);
      return "";
    }

    value = value.tz('Australia/Darwin');

    switch (format) {
      case "utc":
        //typically use this to help debug
        return value.utc().toISOString();
      case "time":
        return value.format("h:mma");
      case "date":
        return value.format("D/MM/YYYY");
      case "std-date":
        return value.format("YYYY-MM-DD");
      case "date-padded":
        return value.format("D/MM/YYYY");
      case "date-long":
        return value.format("D MMMM YYYY");
      case "date-long-with-day":
        return value.format("dddd, D MMMM YYYY");
      case "day":
        return value.format("dddd");
      case "datetime":
        return value.format("D/M/YYYY h:mm A");
      case "datetime-long":
        return value.format("D MMM YYYY h:mm A");
      case "datetime-long-with-day":
        return value.format("dddd, D MMM YYYY [at] h:mm A");
      default:
        return value.format("D/M/YYYY h:mm A");
    }
  }
}