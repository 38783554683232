import { autoinject, bindable } from 'aurelia-framework';
import { GlobalStateService } from 'services/global-state-service';
import { PublicAppointmentDto } from '../../../services/generated-services';
import { ResourceService } from '../../../services/resource-service';
import { WizardSteps } from '../appointment-steps';

@autoinject()
export class AppointmentConfirmed {

    @bindable() currentStep: WizardSteps;
    @bindable() appointment: PublicAppointmentDto;
    resourceName: string = null;

    constructor(
        private readonly resourceService: ResourceService,
        private readonly globalStateService: GlobalStateService
    ) { }

    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    get slotDescription(): string {
        var slotDescription = this.globalStateService.getService().directObjectNoun.toLowerCase();
        return this.capitalizeFirstLetter(slotDescription);
    }

    get actionVerb(): string {
        var actionVerb = this.globalStateService.getService().actionVerb;
        return this.capitalizeFirstLetter(actionVerb);
    }

    async bind() {
        const resourceResponse = await this.resourceService.getResources();
        const resource = resourceResponse.resources.find(i => i.resourceDetail.resourceId == this.appointment.resourceId);
        this.resourceName = resource?.resourceDetail.name;
    }

    bookAgain() {
        //reload the whole things so it discard previous data and reload webpage
        window.location.reload();
    }
}