import { ServiceDto } from "./generated-services";

export class GlobalStateService{
    
    private _service:ServiceDto;

    public getService():ServiceDto{
        return this._service;
    }

    public setService(service:ServiceDto) : void {
        this._service = service;
    }
}