import { ValidationServiceHelper } from './../../../services/validation-service-helper';
import { bindable, containerless, autoinject, Disposable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { find } from 'lodash';
import { ValidationError } from '../../../services/generated-services';

@autoinject()
@containerless()
export class ControlWrapper {

    constructor(private readonly ea: EventAggregator) { }

    @bindable parentProperty: string;
    @bindable propertyName: string;
    @bindable isAddressLookup: boolean = false;
    @bindable additionalClassNames: string = "";
    @bindable useFormGroupClass: boolean = true;
    message: string;
    isValid: boolean = true;
    subscription: Disposable;

    attached() {
        this.subscription = this.ea.subscribe(ValidationServiceHelper.validationSubscriptionEvent, (propertiesError: ValidationError[]) => {
            var propertyName = this.getPropertyName();

            if (propertyName == null)
                return;

            var error = find(propertiesError, i => i.propertyName.toLocaleLowerCase() == propertyName.toLocaleLowerCase());
            if (error) {
                // console.log(error);
                this.isValid = false;
                this.message = error.errorMessage;

                if (this.isAddressLookup) {
                    this.message = "The address chosen is not valid";
                }
            }
            else {
                this.isValid = true;
                this.message = null;
            }
        });
    }

    getPropertyName = () => {
        if (this.parentProperty != null && this.parentProperty != "") {
            return `${this.parentProperty}.${this.propertyName}`;
        }
        return this.propertyName;
    }

    detached() {
        this.subscription.dispose();
    }
}
