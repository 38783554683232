import { computedFrom, } from 'aurelia-binding';
import { autoinject, bindable } from 'aurelia-framework';
import { AddressModel, DynamicFieldDto, DynamicFieldType, AttendeeDto, OptionItemDto } from 'services/generated-services';
import { camelCase } from 'change-case';

@autoinject
export class AttendeeField {

    @bindable attendeePropertyName: string;
    @bindable attendee: AttendeeDto;
    @bindable field: DynamicFieldDto;
    @bindable states: OptionItemDto[];

    bind() {
        if (this.isAddress && !(this.attendee.data[this.fieldName] instanceof AddressModel)) {
            this.attendee.data[this.fieldName] = new AddressModel();
        }
    }

    @computedFrom("field.fieldName")
    get fieldName() {
        return camelCase(this.field.fieldName);
    }

    @computedFrom("field.fieldType")
    get isDate(): boolean {
        return this.field.fieldType == DynamicFieldType.Date;
    }

    @computedFrom("field.fieldType")
    get isText(): boolean {
        return this.field.fieldType == DynamicFieldType.Text;
    }

    @computedFrom("field.fieldType")
    get isDateTime(): boolean {
        return this.field.fieldType == DynamicFieldType.DateTime;
    }

    @computedFrom("field.fieldType")
    get isNumber(): boolean {
        return this.field.fieldType == DynamicFieldType.Number;
    }

    @computedFrom("field.fieldType")
    get isAddress(): boolean {
        return this.field.fieldType == DynamicFieldType.Address;
    }

    @computedFrom("field.fieldType")
    get isEmail(): boolean {
        return this.field.fieldType == DynamicFieldType.Email;
    }

    @computedFrom("field.fieldType")
    get isPhoneNumber(): boolean {
        return this.field.fieldType == DynamicFieldType.PhoneNumber;
    }

    @computedFrom("field.fieldType")
    get isMobileNumber(): boolean {
        return this.field.fieldType == DynamicFieldType.MobileNumber;
    }

    @computedFrom("field.fieldType")
    get inputType(): string {
        if (this.isPhoneNumber || this.isMobileNumber) {
            return "tel";
        }
        if (this.isEmail) {
            return "email";
        }
        if (this.isNumber) {
            return "number";
        }
        return "text";
    }


    @computedFrom("field.fieldType")
    get inputMode(): string {
        if (this.isPhoneNumber || this.isMobileNumber) {
            return "tel";
        }
        if (this.isEmail) {
            return "email";
        }
        if (this.isNumber) {
            return "decimal";
        }
        return "text";
    }
}