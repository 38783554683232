import { BindingEngine, computedFrom, Disposable } from 'aurelia-binding';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import * as _ from 'lodash';
import { GlobalStateService } from 'services/global-state-service';
import { scrollToFirst } from 'utils/scroll-utils';
import { ConfigService } from '../../../services/config-service';
import { ResourceService } from '../../../services/resource-service';
import { WizardSteps } from '../appointment-steps';
import { AppointmentService, ConfirmAppointmentDto, ReserveAppointmentDto, ResourceAvailability, ResourceListItemDto, ScheduleType } from './../../../services/generated-services';

@autoinject()
export class AppointmentSelect {

    @bindable() currentStep: WizardSteps;
    @bindable() booking: ConfirmAppointmentDto;

    areResourcesLoading: boolean = true;
    resources: ResourceAvailability[];
    selectedResource: ResourceAvailability;

    showResourceInformation: boolean = false;
    timeSlotWrap: HTMLDivElement;
    error: string = null;
    appointmentReservationTime: number;
    isLoading: boolean = false;

    @computedFrom('selectedResource')
    get selectedResourceDetail(): ResourceListItemDto {
        if (this.selectedResource == null) {
            return null;
        }
        return this.selectedResource.resourceDetail;
    }

    @computedFrom('resources', 'resources.length')
    get showResources(): boolean {
        return !_.isEmpty(this.resources);
    }

    @computedFrom('areResourcesLoading', 'resources', 'resources.length')
    get showNoResourcesAcceptingBookingError(): boolean {
        return this.areResourcesLoading === false && this.resources != null && this.resources.length === 0;
    }

    subscriptions: Disposable[] = [];

    constructor(private readonly bindingEngine: BindingEngine,
        private readonly appointmentController: AppointmentService,
        private readonly ea: EventAggregator,
        private readonly configService: ConfigService,
        private readonly globalStateService: GlobalStateService,
        private readonly resourceService: ResourceService) { }

    @computedFrom('booking')
    get questionnaireIsPublished(): boolean {
        return this.booking?.isQuestionnairePublished == true;
    }

    get callToChooseText(): string {
        const service = this.globalStateService.getService();
        const isFixedSchedule = service.scheduleType == ScheduleType.Fixed;
        return `Select ${isFixedSchedule ? 'an appointment' : `${service.indefiniteArticleResourceFriendlyName} ${service.resourceFriendlyName}`}`
    }

    get noAppointmentsText(): string {
        var service = this.globalStateService.getService();
        return `There are currently no ${service.directObjectNoun}s available. Please check back later.`;
    }

    async attached() {
        const config = await this.configService.getConfig();
        // Resources are now filtered by questionnaire responses
        await this.resourceService.filterResources(this.booking.questionnaire);

        var resourceResponse = await this.resourceService.getResources();

        this.resources = resourceResponse.resources;

        this.areResourcesLoading = false;
        this.appointmentReservationTime = config.appointmentReservationTime;

        this.subscriptions.push(
            this.bindingEngine.expressionObserver(this.booking.appointment, 'resourceId').subscribe(newValue => {
                this.selectedResource = this.resources.find(i => i.resourceDetail.resourceId === newValue);
                this.showResourceInformation = true;
                scrollToFirst('#resource-information');
                this.ea.publish("reloadResourceSchedule");
            })
        );

        this.subscriptions.push(
            this.ea.subscribe("scrollReserveTimeSlotToView", () => {
                setTimeout(() => {
                    this.timeSlotWrap.scrollIntoView({
                        behavior: "smooth"
                    });
                }, 100)
            })
        )
        if (this.resources.length == 1) {
            this.booking.appointment.resourceId = this.resources[0].resourceDetail.resourceId;
        }
    }

    async goToAppointmentDetails() {
        this.error = null; // Reset error before making request

        for (let response of this.booking.questionnaire.responses) {
            let strippedQuestionText = this.stripHtml(response.questionText);
            response.questionText = strippedQuestionText;
            for (let availableResponse of response.availableResponses) {
                let strippedAvailableResponseResponseText = this.stripHtml(availableResponse.responseText);
                let strippedAvailableResponseDisplayText = this.stripHtml(availableResponse.displayText);
                availableResponse.responseText = strippedAvailableResponseResponseText;
                availableResponse.displayText = strippedAvailableResponseDisplayText;
            }
        }

        const request = new ReserveAppointmentDto();
        request.resourceId = this.selectedResource.resourceDetail.resourceId;
        request.timeSlot = this.booking.timeSlot;
        request.questionnaire = this.booking.questionnaire;

        this.isLoading = true;
        const response = await this.appointmentController.reserveAppointment(this.globalStateService.getService().urlSegment, request);
        this.isLoading = false;

        if (response.success) {
            this.booking.appointment = response.result;
            this.currentStep = WizardSteps.appointmentDetails;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        } else {
            this.ea.publish("reloadResourceSchedule");
            this.error = _.first(response.errors);
        }
    }

    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    stripHtml(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }
}