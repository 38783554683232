import { autoinject, computedFrom } from "aurelia-framework";
import { Router } from "aurelia-router";
import * as _ from "lodash";
import { SystemService } from "../../services/generated-services";

@autoinject
export class EnvRibbon {
  environment: string = null;
  version: string;
  isProduction: boolean = false;

  @computedFrom('environment', 'isProduction')
  get showRibbon(): boolean {
    return this.environment && !this.isProduction;
  }

  constructor(private systemController: SystemService, private router: Router) { }

  async attached() {
    try {
      var settings = await this.systemController.getConfiguration();
      this.environment = settings.environment;
      this.version = settings.versionNumber;
      this.isProduction = settings.isProduction;
    }
    catch {
      this.router.navigateToRoute('apiDown');
    }
  }
}
