import { computedFrom, Disposable } from 'aurelia-binding';
import { autoinject, bindable } from 'aurelia-framework';
import { ValidationController } from 'aurelia-validation';
import dayjs from 'dayjs';
import { GlobalStateService } from 'services/global-state-service';
import { scrollToFirstError } from 'utils/scroll-utils';
import { ConfigService } from '../../../services/config-service';
import { ResourceService } from '../../../services/resource-service';
import { AppointmentService, AttendeeDto, ConfirmAppointmentDto, OptionItemDto, ResourceDetailDto, ScheduleType, ServiceDto } from './../../../services/generated-services';
import { WizardSteps } from './../appointment-steps';

@autoinject()
export class AppointmentDetails {

    @bindable() currentStep: WizardSteps;
    @bindable() booking: ConfirmAppointmentDto;
    @bindable states: OptionItemDto[];

    service: ServiceDto;
    isFixedSchedule: boolean;
    resources: ResourceDetailDto[];
    appointmentReservationTime: number;
    resourceName: string = null;

    attendeeMainHeading: string;
    addOptionalSecondAttendeeChecked: boolean;
    firstAttendee: AttendeeDto;
    optionalSecondAttendee: AttendeeDto;

    error: string = null;
    isLoading: boolean = false;
    subscriptions: Disposable[] = [];

    constructor(
        private readonly appointmentController: AppointmentService,
        private readonly configService: ConfigService,
        private readonly validationController: ValidationController,
        private readonly globalStateService: GlobalStateService,
        private readonly resourceService: ResourceService) {
        this.firstAttendee = new AttendeeDto();
        this.optionalSecondAttendee = new AttendeeDto();
    }

    async bind() {
        const config = await this.configService.getConfig();
        const resourcesResponse = await this.resourceService.getResources();
        const resource = resourcesResponse.resources.find(i => i.resourceDetail.resourceId == this.booking.appointment.resourceId);
        this.resourceName = resource?.resourceDetail.name;

        this.service = this.globalStateService.getService();
        this.isFixedSchedule = this.service.scheduleType == ScheduleType.Fixed;
        this.appointmentReservationTime = config.appointmentReservationTime;
        this.attendeeMainHeading = "Your Details";
    }

    backToAppointmentSelect() {
        this.currentStep = WizardSteps.appointmentSelect;
    }

    async bookAppointment() {
        //reset error from previous attempts
        this.error = null;

        // Send only those attendees who are configured to be sent in the view
        this.booking.appointment.attendees = this.confirmWithSecondAttendee ?
            [this.firstAttendee, this.optionalSecondAttendee] :
            [this.firstAttendee];

        try {
            this.isLoading = true;

            const clientValidationResult = await this.validationController.validate();
            if (!clientValidationResult.valid) {
                scrollToFirstError();
                return;
            }

            //guess the users IANA timezone in case want to send any correspondence in their timezone
            this.booking.appointment.bookedFromTimezone = dayjs.tz.guess();

            for (let response of this.booking.questionnaire.responses) {
                let strippedQuestionText = this.stripHtml(response.questionText);
                response.questionText = strippedQuestionText;
                for (let availableResponse of response.availableResponses) {
                    let strippedAvailableResponseResponseText = this.stripHtml(availableResponse.responseText);
                    let strippedAvailableResponseDisplayText = this.stripHtml(availableResponse.displayText);
                    availableResponse.responseText = strippedAvailableResponseResponseText;
                    availableResponse.displayText = strippedAvailableResponseDisplayText;
                }
            }

            var response = await this.appointmentController.confirmAppointment(this.globalStateService.getService().urlSegment, this.booking);
            if (response.success) {
                this.booking.appointment = response.result;
                this.currentStep = WizardSteps.appointmentConfirmed;
                window.scrollTo({ top: 0, left: 0, behavior: "smooth", });
            } else {
                // Navigate to Appointment Reselect with all information and show schedule only.
                if (response.errors.some(str => str.includes('expire'))) {
                    this.currentStep = WizardSteps.appointmentReselect;
                }
                else {
                    this.error = response.errors.join(" ")
                }
            }
        } catch (err) {
            this.error = "Error occurred while trying to confirm booking.";
        } finally {
            this.isLoading = false;
        }
    }

    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }

    @computedFrom('booking.timeSlot.maxAttendees')
    get optionalSecondAttendeeAvailable(): boolean {
        return this.booking?.timeSlot?.maxAttendees && this.booking?.timeSlot?.maxAttendees >= 2;
    }

    @computedFrom('addOptionalSecondAttendeeChecked')
    get confirmWithSecondAttendee(): boolean {
        return this.optionalSecondAttendeeAvailable && this.addOptionalSecondAttendeeChecked;
    }

    stripHtml(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }
}