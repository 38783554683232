export class IeWarning {
    showWarning: boolean = false;

    bind() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, show warning
        {
            this.showWarning = true;
        }
    }

    closeWarning() {
        this.showWarning = false;
    }
}