import { EventAggregator } from 'aurelia-event-aggregator';
import { Disposable, autoinject, bindable } from 'aurelia-framework';
import * as _ from 'lodash';
import { GlobalStateService } from 'services/global-state-service';
import { ConfigService } from '../../../services/config-service';
import { ResourceService } from '../../../services/resource-service';
import { AppointmentService, ConfirmAppointmentDto, ResourceAvailability, ScheduleType } from './../../../services/generated-services';
import { WizardSteps } from './../appointment-steps';

@autoinject()
export class AppointmentReselect {

    @bindable() currentStep: WizardSteps;
    @bindable() booking: ConfirmAppointmentDto;
    resources: ResourceAvailability[];
    selectedResource: ResourceAvailability;
    appointmentReservationTime: number;
    bookingBtnWrap: HTMLDivElement;
    error: string = null;
    subscriptions: Disposable[] = [];
    isLoading: boolean = false;

    constructor(private readonly ea: EventAggregator,
        private readonly appointmentController: AppointmentService,
        private readonly configService: ConfigService,
        private readonly globalStateService: GlobalStateService,
        private readonly resourceService: ResourceService) { }

    async bind() {

        await this.resourceService.filterResources(this.booking.questionnaire);
        var resourceResponse = await this.resourceService.getResources();
        this.resources = resourceResponse.resources;

        // Needs to be before reload Resource schedule is called
        this.selectedResource = this.resources.find(i => i.resourceDetail.resourceId == this.booking.appointment.resourceId);

        var config = await this.configService.getConfig();
        this.appointmentReservationTime = config.appointmentReservationTime;

        this.ea.publish("reloadResourceSchedule");

        this.subscriptions.push(
            this.ea.subscribe('scrollReserveTimeSlotToView', () => {
                this.bookingBtnWrap.scrollIntoView({
                    behavior: 'smooth'
                })
            })
        )
    }

    async confirmBooking() {
        this.error = null;
        try {
            this.booking.appointment.appointmentDate = this.booking.timeSlot.start;

            this.isLoading = true;

            var response = await this.appointmentController
                .confirmAppointment(this.globalStateService.getService().urlSegment, this.booking);

            if (response.success) {
                this.booking.appointment = response.result;
                this.currentStep = WizardSteps.appointmentConfirmed;
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            } else {
                const isTokenError = response.errors.some((item) => item.toLowerCase().includes("token"));
                if (isTokenError) {
                    //redirect to the homepage - expired token
                    location.reload();
                }
                this.error = _.first(response.errors);
            }
        } catch (err) {
            this.error = "Error occurred while trying to confirm booking.";
            console.error(err);
        }
        this.isLoading = false;
    }

    get callToChooseText(): string {
        const service = this.globalStateService.getService();
        const isFixedSchedule = service.scheduleType == ScheduleType.Fixed;
        return `Reselect ${isFixedSchedule ? 'an appointment' : `${service.indefiniteArticleResourceFriendlyName} ${service.resourceFriendlyName}`}`
    }


    detached() {
        while (this.subscriptions.length > 0) {
            this.subscriptions.pop().dispose();
        }
    }
}