import dayjs from "dayjs";

//this conversion is for expressing a local "day" in darwin days
export function localToDarwinStartOfDay(localDate: dayjs.Dayjs) {

    //empty tz() should convert to local date representation
    //then we can convert to darwin and get the start of day
    const dateString = localDate.tz().format("D/MM/YYYY");
    // console.log("toDarwinStartOfDay: ", dateString)

    const darwinStartOfDay = dayjs.tz(dateString, "D/MM/YYYY", 'Australia/Darwin').startOf('day');
    // console.log({ dateString, darwinStartOfDay: darwinStartOfDay.toISOString() });

    return darwinStartOfDay;
}

//conversion used usually for rendering purposes
export function darwinToLocalStartOfDay(darwinDate: dayjs.Dayjs) {
    
    const dateString = dayjs(darwinDate).tz('Australia/Darwin').format("D/MM/YYYY");
    // console.log("toLocalStartOfDay: ", dateString)
    
    const localStartOfDay = dayjs(dateString, 'D/MM/YYYY').startOf('day');
    // console.log({ dateString, localStartOfDay: localStartOfDay.toISOString() });
    
    return localStartOfDay;
}