import { autoinject, bindable, containerless } from 'aurelia-framework';
import { IProgressStep } from './progress-bar';
import { computedFrom } from 'aurelia-binding';

@autoinject
@containerless
export class ProgressStep {
    @bindable currentStep: string;
    @bindable currentStepNumber: number;
    @bindable step: IProgressStep;
    @bindable stepNumber: number;

    @computedFrom("step.stepKey", "currentStep")
    get status(): string {

        if (this.step.stepKey == this.currentStep) {
            return 'active';
        }
        else if (this.currentStepNumber > this.stepNumber) {
            return 'complete'
        }
        return '';
    }
}
