import { Disposable, autoinject, bindable, containerless } from 'aurelia-framework';

@autoinject()
@containerless()
export class RequiredInfo {

    @bindable() propertyName: string;
    message: string = "Required info - please complete";
    isValid: boolean = true;
    subscription: Disposable;

    attached() {
        // this.subscription = this.ea.subscribe(ValidationFeature.validationSubscriptionEvent, (propertiesError: IValidationError[]) => {

        //     var propertyName = this.propertyName;
        //     // console.log(propertyName);

        //     if (propertyName == null)
        //         return;

        //     var error = find(propertiesError, i => i.propertyName.toLocaleLowerCase() == propertyName.toLocaleLowerCase());

        //     // console.log(error);

        //     if (error) {
        //         this.isValid = false;
        //         this.message = error.errorMessage;
        //     }
        //     else {
        //         this.isValid = true;
        //         this.message = null;
        //     }
        // });
    }

    detached() {
        this.subscription.dispose();
    }
}