import { FrameworkConfiguration } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-framework';

export function configure(aurelia: FrameworkConfiguration) {
    aurelia.globalResources([
        PLATFORM.moduleName("./date-time-format-value-converter"),
        PLATFORM.moduleName("./split-value-converter"),
        PLATFORM.moduleName("./uncapitalise-first-letter-value-converter"),
        PLATFORM.moduleName("./yes-no-value-converter"),
    ]);
}
