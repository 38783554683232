export function scrollToFirst(selector: string) {
    setTimeout(() => {
        var element = document.querySelector(selector);
        if (element) {
            element.scrollIntoView({
                behavior: "smooth"
            });
        }
    }, 50);
}

export function scrollToFirstError() {
    scrollToFirst('.is-invalid')
}