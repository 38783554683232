import { Aurelia, PLATFORM, } from 'aurelia-framework';
import 'polyfill-array-includes';
import 'whatwg-fetch';
import '../styles/app.css';
import { ServiceBase } from 'services/service-base';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

declare global {
    var __APP_BASE_URL__: string;
    var ENV: 'development' | 'production'; //injected via webpack
}

dayjs.extend(customParseFormat);
dayjs.extend(utc)
dayjs.extend(timezone)

function initGoogleAnalytics(account:string){
    
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({"js":new Date()});
    window['dataLayer'].push({"config":account});

  (function() {
    var ga = document.createElement('script'); 
    ga.type = 'text/javascript'; 
    ga.async = true;
    ga.src = "https://www.googletagmanager.com/gtag/js?id=" + account;
    var head = document.getElementsByTagName('head')[0]; 
    head.insertBefore(ga, head.firstChild);
  })(); 
}

export async function configure(aurelia: Aurelia): Promise<void> {
    if (navigator.userAgent.indexOf('MSIE') !== -1) {
        //do not start if IE < 11
        return;
    }

    var response = await fetch("/config.json");
    var result = await response.json();
    if (result.googleAnalyticsAccount){
        initGoogleAnalytics(result.googleAnalyticsAccount);
    }
    ServiceBase.baseUrl = result.baseUrl;

    aurelia.use.standardConfiguration()
        .plugin(PLATFORM.moduleName('aurelia-animator-css'))
        .plugin(PLATFORM.moduleName('aurelia-dialog'))
        .plugin(PLATFORM.moduleName('aurelia-validation'))
        .feature(PLATFORM.moduleName('components/index'))
        .feature(PLATFORM.moduleName('value-converters/index'));

    if (ENV !== 'production') {
        aurelia.use.developmentLogging();
    }

    try {
        await aurelia.start();
        await aurelia.setRoot(PLATFORM.moduleName('app'));
    } catch (error) {
        console.error(error);
        alert(error);
    }
}