import { autoinject, customAttribute } from "aurelia-framework";

@autoinject()
@customAttribute("optional")
export class OptionalAttribute {

    private _flagSet: boolean = false;

    constructor(private readonly element: Element) { }

    bind() {
        if (!this._flagSet)
            this.element.innerHTML += `<span class="text-sm text-gray-400 ml-2"> Optional</span>`;
        this._flagSet = true;

    }
}