import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { scrollToFirstError } from '../utils/scroll-utils';
import { ValidationResults } from './generated-services';

@autoinject()
export class ValidationServiceHelper {

    static validationSubscriptionEvent: string = "ValidationSubscriptionEvent";

    constructor(private readonly ea: EventAggregator) { }

    showValidationErrors(validationResults: ValidationResults): void {
        this.ea.publish(ValidationServiceHelper.validationSubscriptionEvent, validationResults.validationErrors);

        if (validationResults.isValid == false) {
            scrollToFirstError();
        }
    }
}