import { RequestInit } from 'aurelia-fetch-client';
// import { Container } from 'aurelia-dependency-injection';

export class ServiceBase {

    public static baseUrl:string;

    protected transformOptions(options: RequestInit) {
        options.credentials = "include";
        // options.headers["client-version"] = ReleaseInfo.version;
        return Promise.resolve(options);
    }

    protected async transformResult(url: string, response: Response, processor: (response: Response) => any) {
        // let systemVersion = response.headers.get("system-version");
        // var Feature = Container.instance.get(VersionCheckerFeature);
        // await Feature.performCheck(systemVersion);
        return processor(response);
    }

    protected getBaseUrl(base: string, what: string): string {
        return ServiceBase.baseUrl;
    }
}
