
import { EventAggregator } from 'aurelia-event-aggregator';
import { PLATFORM, autoinject } from 'aurelia-framework';
import { NavigationInstruction, PipelineResult, RouteConfig, Router, RouterConfiguration, RouterEvent } from 'aurelia-router';

export const ROUTE_API_DOWN = 'api-down';
export const ROUTE_SERVER_ERROR = 'server-error';
export const ROUTE_SERVICE_LANDING = 'service-landing'
export const ROUTE_APPOINTMENT = 'service-landing-appointment';

@autoinject()
export class App {

  constructor(
    private readonly ea: EventAggregator
  ) {
    let subscription = this.ea.subscribe(RouterEvent.Complete, (event: { instruction: NavigationInstruction; result: PipelineResult }) => {
      document.getElementById('loader').classList.add('fadeout');
      subscription.dispose();
    });
  }

  footerItems = [
    { title: "Privacy", link: "https://service.nt.gov.au/privacy/online-bookings" },
    { title: "Copyright and disclaimer", link: "https://service.nt.gov.au/copyright-and-disclaimer" },
    { title: "Interpreter services", link: "https://nt.gov.au/page/interpreter-services" },
    { title: "Accessibility", link: "https://nt.gov.au/page/accessibility" },
    { title: "Contacts", link: "https://nt.gov.au/contacts" }
  ];

  currentYear: number = new Date().getFullYear();


  configureRouter(config: RouterConfiguration, router: Router): void {
    config.title = 'Territory Appointments';
    config.options.pushState = true;

    config.map(<RouteConfig[]>([
      {
        route: '',
        redirect: 'not-found'
      },
      {
        route: 'service/:service',
        name: ROUTE_SERVICE_LANDING,
        moduleId: PLATFORM.moduleName('pages/service-landing/service-landing'),
      },
      {
        route: 'api-down',
        name: ROUTE_API_DOWN,
        moduleId: PLATFORM.moduleName('pages/status/api-down.html'),
        title: 'Application is offline',
        nav: false
      },
      {
        route: 'error',
        name: ROUTE_SERVER_ERROR,
        moduleId: PLATFORM.moduleName('pages/status/server-error.html'),
        title: 'Unexpected error',
        nav: false
      },
      {
        route: 'not-found',
        name: ROUTE_SERVER_ERROR,
        moduleId: PLATFORM.moduleName('pages/status/not-found.html'),
        title: 'Not found',
        nav: false
      }
    ]));
    config.fallbackRoute('not-found');
  }
}
