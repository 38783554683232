import { validateTrigger, ValidationController, ValidationControllerFactory } from "aurelia-validation";
import { ValidationRenderer } from "./validation-renderer";

export class ValidationBase {

    public validationController: ValidationController;

    constructor(controllerFactory: ValidationControllerFactory) {
        this.validationController = controllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.changeOrBlur;
        this.validationController.addRenderer(new ValidationRenderer());
    }
}
