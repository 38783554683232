import { bindingMode, computedFrom } from 'aurelia-binding';
import { autoinject } from 'aurelia-framework';
import { bindable } from "aurelia-templating";
import dayjs from 'dayjs';
import Flatpickr from 'flatpickr';

@autoinject()
export class InputDate {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) date: dayjs.Dayjs;
    @bindable disabled: boolean = false;
    @bindable enabledDates: dayjs.Dayjs[] = [];

    flatElement: HTMLInputElement;
    id: string;
    _flatpickr: any;

    enabledDatesChanged(newValue: dayjs.Dayjs[]) {
        if (this.enabledDates?.length > 0 && this._flatpickr != null) {
            this._flatpickr.set('enable', this.getEnableDatesInFlatPickerFormat());
        }
    }

    attached() {
        const config: any = {
            disableMobile: true,
            defaultDate: this.date ? this.date.toDate() : null,
            allowInput: true,
            clickOpens: false,
            dateFormat: 'd/m/Y',
            allowInvalidPreload: false
        };

        if (this.enabledDates?.length > 0) {
            config.enable = this.getEnableDatesInFlatPickerFormat();
        }

        this._flatpickr = Flatpickr(this.flatElement, config);
        this._flatpickr.config.onChange.push((selectedDates: any, dateStr: any, instance: any) => {
            if (dateStr != "") {
                this.date = dayjs(dateStr, 'DD/MM/YYYY');
            } else {
                this.date = null;
            }
        });
    }

    dateChanged(newValue: dayjs.Dayjs, oldValue: dayjs.Dayjs) {
        if (this._flatpickr && (!newValue && oldValue) && (!newValue || !newValue.isValid())) {
            this._flatpickr.setDate(null);
            return;
        }
        if (this._flatpickr && newValue && (newValue != oldValue || !newValue.isSame(oldValue))) {
            this._flatpickr.setDate(dayjs(this.date).toDate());
        }
    }

    getEnableDatesInFlatPickerFormat() {
        return this.enabledDates.map(i => i.toDate());
    }

    open(event: Event) {
        event.stopPropagation();
        this._flatpickr.toggle();
    }

    detached() {
        if (this._flatpickr) {
            this._flatpickr.destroy();
            this._flatpickr = null;
        }
    }
}