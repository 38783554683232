import { ROUTE_APPOINTMENT } from "app";
import { autoinject } from "aurelia-dependency-injection";
import { PLATFORM } from "aurelia-framework";
import { RouteConfig, Router, RouterConfiguration } from "aurelia-router";
import { ServiceService, ServiceDto } from "services/generated-services";
import { GlobalStateService } from "services/global-state-service";

@autoinject()
export class ServiceLanding{
    private service:ServiceDto;

    constructor(
        private readonly router:Router, 
        private readonly globalStateService:GlobalStateService,
        private readonly serviceController:ServiceService,
        ){

    }

    async activate(params: { service: string}) {
        
        this.service = await this.serviceController.get(params.service);
            //all child pages and sub components can request this in the constructor    
        this.globalStateService.setService(this.service );
    }

    configureRouter(config: RouterConfiguration, router: Router): void {
        config.map(<RouteConfig[]>([
            {
                route:'',
                name: "landing redirect",
                redirect: 'appointment' 
            },
            {
                route: 'appointment/:step?',
                name: ROUTE_APPOINTMENT,
                moduleId: PLATFORM.moduleName('pages/appointment-process/appointment-process'),
                title: 'Book an appointment'
            },
            {
                route: 'cancel/:token',
                name: 'cancel',
                moduleId: PLATFORM.moduleName('pages/cancel-appointment/cancel-appointment'),
                title: 'Cancel your appointment'
              }
          ]));
    }
}
