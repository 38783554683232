import { autoinject, bindable, containerless } from 'aurelia-framework';
import { GlobalStateService } from 'services/global-state-service';

@containerless()
@autoinject()
export class ThemeText {

    text: string;
    @bindable() themeKey: string;

    constructor(private readonly globalStateService: GlobalStateService) { }

    async attached() {
        //var service = await this.globalStateService.getService();
        //this.text = service. theme[this.themeKey];
    }
}