import { bindable } from 'aurelia-framework';
import { ConfirmAppointmentDto, OptionItemDto } from '../../services/generated-services';

export enum WizardSteps {
    appointmentQuestions = 'appointmentQuestions',
    appointmentSelect = 'appointmentSelect',
    appointmentDetails = 'appointmentDetails',
    appointmentConfirmed = 'appointmentConfirmed',
    appointmentReselect = 'appointmentReselect'
}

export class AppointmentSteps {
    @bindable currentStep: WizardSteps;
    @bindable booking: ConfirmAppointmentDto;
    @bindable skipDisclaimer: boolean;
    @bindable states: OptionItemDto[];
}